import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/Layout"

export default function DaccaGroup({ data }) {
  const details = data.strapiDetails

  return (
    <Layout
      Seo={{ title: "DACCA Group" }}
      noHero
      content={
        <section className="section-container px-4 md:px-8 lg:px-12">
          <div className="flex flex-col items-center text-center regular leading-loose text-sm lg:text-base max-w-screen-lg">
            <h1 className="playfair-title text-3xl">DACCA Group</h1>
            <div className="space-y-6">
              <p>
                At DACCA, we collaborate between internally owned groups
                consisting of, but not limited to Development, Architecture,
                Construction, Collaboration and Administration.
              </p>
              <div className="space-y-2">
                <p>
                  These groups provide the construction industry with a unique
                  and professional service stemming from one DNA.
                </p>
                <p>
                  We have customised systems, cadetships, mentoring, and
                  training internally across all groups.
                </p>
                <p>
                  We encourage leadership and cross-pollination among trades to
                  create unique solutions and opportunities.
                </p>
                <p>
                  These groups provide a one-stop shop for design, construction,
                  creativity and innovation. Providing our clients a
                  professional, collaborative, and profitable solution.
                </p>
              </div>
              <div>
                <p className="mb-2">
                  The following business support services will be performed by
                  the Service Provider:
                </p>
                <p>
                  • providing business network opportunities by way of
                  introduction to leads from other DACCA licensees • providing
                  an introduction to professional advisers including accountants
                  and human resource professionals • website development and
                  maintenance • providing multimedia (Facebook and Instragram,
                  LinkedIn, etc) for the wider DACCA network • providing office
                  space access
                </p>
              </div>
              <div className="space-y-2">
                <p>
                  If you would like to join the DACCA Group, please contact Tony
                  for more details.
                </p>
                <p>
                  Tony Maluccio, Nominated Architect 9428 (Antonio Maluccio)
                </p>
                <p>{details.tonyEmail}</p>
                <p>w {details.tonyNumber}</p>
              </div>
            </div>
          </div>
        </section>
      }
    />
  )
}

export const query = graphql`
  query Groupdetails {
    strapiDetails {
      tonyEmail
      tonyNumber
    }
  }
`
